import { Component, ViewChild, OnInit } from "@angular/core";
import { MatSidenav } from "@angular/material/sidenav";
import { MaterialModule } from "src/app/common/material.module";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { HttpClient } from "@angular/common/http";
import { HttpProvider } from "../../common/http/http.provider";
import {​​​​​​SidenavComponent}​​​​​​ from "../sidenav/sidenav.component";
import {​​​​​​ Router }​​​​​​ from "@angular/router";
import {
  faSort,
  faPlus,
  faEdit,
  faTimes,
  faTrash,
  faHome,
  faCaretRight
} from "@fortawesome/free-solid-svg-icons";
@Component({
  selector: 'app-customer-dashboard',
  templateUrl: './customer-dashboard.component.html',
  styleUrls: ['./customer-dashboard.component.css']
})
export class CustomerDashboardComponent implements OnInit {

  constructor(private component:SidenavComponent,private _router: Router,private http: HttpProvider, private httpClient: HttpClient,) { }
  faSort = faSort;
  faPlus = faPlus;
  faEdit = faEdit;
  faTrash = faTrash;
  faTimes = faTimes;
  faHome = faHome;
  faCaretRight = faCaretRight;
  navOpen:boolean = false;
  cargoCount: any = [];
  ngOnInit(): void {
    this.getCargoCount();
  }
homeLink(){
this.component.ngOnInit();
if(localStorage.getItem('Role')=='Admin'){
this._router.navigateByUrl("/adminDashboard");
}else{
this._router.navigateByUrl("/dashboard");
}
}
getCargoCount() {
  this.httpClient
    .get(this.http.BASE_URI + "getCargoCount")
    .subscribe((response: any) => {
      // console.log("Response", response);
      this.cargoCount = response;
    });
}
}
