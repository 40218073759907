import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from "@angular/core";
import { LocationStrategy, PathLocationStrategy } from "@angular/common";
import {MaterialModule} from './common/material.module'
import {SharedModule} from './common/shared.module'
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SidenavComponent } from './components/sidenav/sidenav.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { SuperadminComponent } from './components/superadmin/superadmin.component';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import { MDBBootstrapModule } from 'angular-bootstrap-md';
import { JwPaginationModule } from 'jw-angular-pagination';
// import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from '@danielmoncada/angular-datetime-picker';
// import { ForgetPasswordComponent } from './components/forget-password/forget-password.component';
// import { MatNativeDateModule } from "@angular/material/core";
// import { MatMomentDateModule } from "@angular/material-moment-adapter";
import { HttpClientModule } from '@angular/common/http';
// import { ForgetPasswordLinkComponent } from './components/forget-password-link/forget-password-link.component'
import { NgxUiLoaderModule } from "ngx-ui-loader";
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { ShipmentMoniterComponent } from './components/shipment-moniter/shipment-moniter.component';
import { CustomerDashboardComponent } from './components/customer-dashboard/customer-dashboard.component';
import { ClaimRequestComponent } from './components/claim-request/claim-request.component';
import { ReportsComponent } from './components/reports/reports.component';
import { InvoiceCustomComponent } from './components/invoice-custom/invoice-custom.component';
import { QuotationComponent } from './components/quotation/quotation.component';
import { AdjusmentsComponent } from './components/adjusments/adjusments.component';
@NgModule({
  declarations: [                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                   
    AppComponent,
    SidenavComponent,
    SuperadminComponent,
    ShipmentMoniterComponent,
    CustomerDashboardComponent,
    ClaimRequestComponent,
    ReportsComponent,
    InvoiceCustomComponent,
    QuotationComponent,
    AdjusmentsComponent,
  ],
  imports: [
    HttpClientModule,
    BrowserModule,
    NgbModule,
    AppRoutingModule,
    MaterialModule,
    BrowserAnimationsModule,
    SharedModule,
    ReactiveFormsModule,
    FormsModule,
    JwPaginationModule,
    OwlDateTimeModule, OwlNativeDateTimeModule,
    // MatMomentDateModule,
    MDBBootstrapModule.forRoot(),
    NgxUiLoaderModule,
    Ng2SearchPipeModule
  ],
  providers: [
    { provide: LocationStrategy, useClass: PathLocationStrategy },
    // MatNativeDateModule
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [AppComponent]
})
export class AppModule { }
