import {
  Component,
  HostListener,
  OnInit,
  ViewChild,
  ChangeDetectorRef,
} from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import {
  faSort,
  faPlus,
  faEdit,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { MdbTableDirective, MdbTablePaginationComponent } from 'angular-bootstrap-md';
import { HttpClient } from "@angular/common/http";
import { environment } from "./../../../environments/environment";
import { HttpProvider } from "../../common/http/http.provider";
import { HttpHeaders } from "@angular/common/http";
import { NgxUiLoaderService } from "ngx-ui-loader"; // Import NgxUiLoaderService
import { canActive } from "../../common/helpers/authgouard";
import { SelectionModel } from "@angular/cdk/collections";
import { MatTableDataSource } from "@angular/material/table";

declare var $: any;
@Component({
  selector: "app-claim-request",
  templateUrl: "./claim-request.component.html",
  styleUrls: ["./claim-request.component.css"],
})
export class ClaimRequestComponent implements OnInit {
  
  showModel: any = false;
  submitButton: any = true;
  updateButton: any = false;
  registerForm: FormGroup;
  submitted = false;
  faSort = faSort;
  faPlus = faPlus;
  faEdit = faEdit;
  faTrash = faTrash;
  shiperDetails: any[];
  items: any[];
  @ViewChild(MdbTableDirective, { static: true }) mdbTable: MdbTableDirective;
  elements: any[];
  showItem = false;
  headElements=['id','shipper name','consignee name','request date','status'];
  claimDetails: any = {
    id:'',
    shippername: "",
    items: "",
    description: "",
  };
  constructor(
    private formBuilder: FormBuilder,
    private http: HttpProvider,
    private httpClient: HttpClient,
    private ngxService: NgxUiLoaderService
  ) {}

  page = 1;
  pageSize = 10;
  ngOnInit() {
    this.ngxService.start();
    this.getdata();
    this.registerForm = this.formBuilder.group({
      shippername: ["", Validators.required],
      items: ["", Validators.required],
      description: ["", Validators.required],
    });
    this.getshipperinfo();
  }
  
  get f() {
    return this.registerForm.controls;
  }
  configFormModel() {
    this.showModel = true;
    // this.insertbutton = true;
  }
  cancel() {
    this.showModel = false;
  }
  getdata() {
    this.httpClient
      .get(this.http.BASE_URI + "listClaim/" + localStorage.getItem("Id"))
      .subscribe((response: any) => {
        // console.log("Response", response);
         this.elements = response;
        //  console.log(this.elements)
      });
  }
  getshipperinfo() {
    this.httpClient
      .get(
        this.http.BASE_URI +
          "customer/getCargoItems/" +
          localStorage.getItem("Id")
      )
      .subscribe((response: any) => {
        // console.log("Response", response);
        this.shiperDetails = response.all;
        this.ngxService.stop();
      });
  }
  onSubmitClick() {
    // console.log("onsubmit", true);
    this.submitted = true;
    if (this.registerForm.invalid) {
      return;
    }
    this.onSend('update');
  }
  onUpdateClick() {
    this.submitted = true;
    if (this.registerForm.invalid) {
      return;
    }
    this.onSend('insert');
  }
  shipmentInfoChange(e) {
    if (e == "") {
      return;
    }
    // console.log(e);
    this.ngxService.start();
    this.httpClient
      .get(this.http.BASE_URI + "getCargoItems/" + e)
      .subscribe((response: any) => {
        // console.log("Response", response);
        this.items = response;
        this.showItem = true;
        this.ngxService.stop();
      });
  }
  onSend(e) {
    let request = {
      customer_id: localStorage.getItem('Id'),
      shipping_id:this.claimDetails.shippername,
      item_id: this.claimDetails.items,
      description:this.claimDetails.description,
      id: this.claimDetails.id,
    };
    this.httpClient
      .post(this.http.BASE_URI + 'addUpdateClaim', request)
      .subscribe((response: any) => {
        // console.log("Response", response)
        this.getdata();
        if (e == 'insert') {
          Swal.fire('Thank you...', 'Your Data Inserted Succesfully!', 'success');
        } else {
          Swal.fire('Thank you...', 'Your Data Edited Succesfully!', 'success');
        }this.cancel();
      });
    // addUpdateClaim
  }
  onRowCreate(e,i){
    this.showModel = true;
    this.updateButton = true;
    this.submitButton = false;
    this.claimDetails.id=e.id;
    this.claimDetails.shippername = e.shipping_id;
    this.claimDetails.items = e.item_id;
    this.claimDetails.description = e.description;
  }
  onRowRemove(e, i) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data!',
      // icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.isConfirmed) {
        this.ngxService.start();
        this.httpClient
          .delete(this.http.BASE_URI + 'deleteClaim/'+e.id)
          .subscribe((response: any) => {
            // console.log("Response", response)
            this.getdata();
            this.cancel();
            this.ngxService.stop();
          });
        Swal.fire(
          'Deleted!',
          'Your data has been deleted.',
          'success'
        )
        // For more information about handling dismissals please visit
        // https://sweetalert2.github.io/#handling-dismissals
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })
  }
}
