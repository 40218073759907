import { Component, OnInit } from '@angular/core';
import { faHome , faCaretRight} from "@fortawesome/free-solid-svg-icons";
import {​​​​​​SidenavComponent}​​​​​​ from "../sidenav/sidenav.component";
import {​​​​​​ Router }​​​​​​ from "@angular/router";
@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.css']
})
export class ReportsComponent implements OnInit {

  faHome = faHome;
  faCaretRight = faCaretRight;
  navOpen:boolean = false;
  constructor(private component:SidenavComponent,private _router: Router  ) { }

  ngOnInit(): void {
  }
homeLink(){
this.component.ngOnInit();
if(localStorage.getItem('Role')=='Admin'){
this._router.navigateByUrl("/adminDashboard");
}else{
this._router.navigateByUrl("/dashboard");
}
}
}
