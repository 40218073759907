import { Component, HostListener, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { faSort, faPlus, faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';
import { MdbTableDirective, MdbTablePaginationComponent } from 'angular-bootstrap-md';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbDateStruct, NgbCalendar } from '@ng-bootstrap/ng-bootstrap';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { getMaxListeners } from 'process';
import { HttpClient } from "@angular/common/http";
import { environment } from "./../../../environments/environment";
import { HttpProvider } from "../../common/http/http.provider";
import { HttpHeaders } from '@angular/common/http';
import { NgxUiLoaderService } from "ngx-ui-loader"; // Import NgxUiLoaderService
import { canActive } from "../../common/helpers/authgouard";
declare var $: any;
@Component({
  selector: 'app-superadmin',
  templateUrl: './superadmin.component.html',
  styleUrls: ['./superadmin.component.css']
})
export class SuperadminComponent implements OnInit {
  constructor(private modalService: NgbModal, private cdRef: ChangeDetectorRef, private formBuilder: FormBuilder, private calendar: NgbCalendar, private http: HttpProvider, private httpClient: HttpClient, private ngxService: NgxUiLoaderService) {
    if (canActive("Admin") == false) {
      let str = window.location.href;
      let myArr = str.split("#");
      window.location.replace(myArr[0]+"#/login");
    }
   }
  showModel: any = false;
  submitButton: any = true;
  updateButton: any = false
  registerForm: FormGroup;
  submitted = false;
  stateshow: boolean = false;
  citieseshow: boolean = false;
  indexvalue: any = "";
  roles: any = [];
  countries: any = '';
  states: any = '';
  cities: any = "";
  countryvalues:any="";
  cityvalues:any="";
  statevalues:any="";
  check: any = false;
  customer: any = {
    id:"",
    name: "",
    email: "",
    mobile: "",
    address: "",
    roles: "",
    country: "",
    state: "",
    city: "",
    zipcode: ""
  }
  request: any = ""
  closeResult: string;
  faSort = faSort;
  faPlus = faPlus;
  faEdit = faEdit;
  faTrash = faTrash;
  @HostListener('input') oninput() {
    this.searchItems();
  }
  @ViewChild(MdbTableDirective, { static: true }) mdbTable: MdbTableDirective;
  elements: any = [];
  page = 1;
  pageSize = 10;
  headElements = ['name', 'email', 'mobile', 'country', 'role'];
  searchText: string = '';
  previous: string;
  // indexvalue = 5;

  open(content) {
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }
  ngOnInit(): void {
    this.ngxService.start();
    this.getdata();
    this.registerForm = this.formBuilder.group({
      name: ['', Validators.required],
      email: ['', [Validators.required,Validators.email]],
      mobile: ['', [Validators.required,Validators.minLength(6)]],
      address: ['', Validators.required],
      roles: ['', Validators.required],
      country: ['', Validators.required],
      state: [''],
      city: [''],
      zipcode: ['', Validators.required]
    });
    // this.elements.push(this.dataEntry);
  }
  searchItems() {
    const prev = this.mdbTable.getDataSource();
    if (!this.searchText) {
      this.mdbTable.setDataSource(this.previous);
      this.elements = this.mdbTable.getDataSource();
    }
    if (this.searchText) {
      this.elements = this.mdbTable.searchLocalDataByMultipleFields(this.searchText, ['name', 'email', 'mobile', 'address', 'role']);
      this.mdbTable.setDataSource(prev);
    }
  }
  onRowCreate(e, i) {
    this.showModel = true;
    this.updateButton = true;
    this.submitButton = false;
    // this.indexvalue = i;
    this.customer.id=e.id;
    this.customer.name = e.name;
    this.customer.mobile = e.phone;
    this.customer.email = e.email;
    this.customer.address = e.address;
    this.customer.country = e.country
    this.customer.state = e.state;
    this.customer.city = e.city;
    this.customer.roles = e.role;
    this.customer.zipcode = e.zipcode;
    // console.log('e====>', e, i);

  }
  onRowRemove(e, i) {
    this.indexvalue = i;
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data!',
      // icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.isConfirmed) {
        this.ngxService.start();
        this.httpClient
          .post(this.http.BASE_URI + 'deactivateUser/'+e.id, "")
          .subscribe((response: any) => {
            // console.log("Response", response)
            this.getdata();
            this.onReset();
            this.cancel();
            this.ngxService.stop();
          });
        Swal.fire(
          'Deleted!',
          'Your data has been deleted.',
          'success'
        )
        // For more information about handling dismissals please visit
        // https://sweetalert2.github.io/#handling-dismissals
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })
  }
  get f() { return this.registerForm.controls; }
  onSubmitClick() {
    // console.log('onsubmit',true)
    this.submitted = true;
    if (this.registerForm.invalid) {
      return;
    }
    this.onSend('insert');
  }
  onUpdateClick() {
    this.submitted = true;
    if (this.registerForm.invalid) {
      return;
    }
    this.onSend('update')
  }
  onReset() {
    this.submitted = false;
    this.registerForm.reset();
    this.updateButton = false;
    this.submitButton = true;
  }
  aftersubmit() {
    // $('.modal').modal('hide');
    // $("#myModal").modal("hide");
    // Swal.fire('Thank you...', 'You submitted succesfully!', 'success')
  }
  configFormModel() {
    this.onReset();
    this.showModel = true;
    // this.insertbutton = true;
  }
  cancel() {
    this.showModel = false;
  }
  getKeyByValue(object, value) {
    return Object.keys(object).find((key) => object[key] === value);
  }
  countryselect(e) {
    // console.log("contry===>", e == '')
    if (e == null || e == "") {
      return
    }
    // console.log();
    // console.log("\n country value===>", e)
    this.ngxService.start();
    this.httpClient
      .get(this.http.BASE_URI + 'statelist/' + this.getKeyByValue(this.countries, e))
      .subscribe((response: any) => {
        // console.log("Response", response)
        this.states = response;
        this.stateshow = true;
        this.ngxService.stop();
      });
      this.ngxService.stop();
  }
  stateselect(e) {
    if (e == null || e == "") {
      return
    }
    // console.log("\n country value===>", this.customer.country)
    this.ngxService.start();
    this.httpClient
      .get(this.http.BASE_URI + 'citylist/' + this.getKeyByValue(this.countries,this.customer.country))
      .subscribe((response: any) => {
        // console.log("Response", response)
        this.cities = response;
        this.citieseshow = true;
        this.ngxService.stop();
      });
  }
  onSelection(e) {
    // console.log('eventTriggerd\n')
    // console.log("ModelChange=====>", e)
  }
  getdata() {
    this.httpClient
      .get(this.http.BASE_URI + 'listusers')
      .subscribe((response: any) => {
        // console.log("Response", response)
        this.elements = response.data.users.active;
        this.mdbTable.setDataSource(this.elements);
        this.previous = this.mdbTable.getDataSource();
        this.cdRef.detectChanges();
        let d = [];
        (response.data.role).forEach(element => {
          d.push(element.role)
        });
        this.roles = d;
        // console.log(this.roles)
        this.countries = response.data.countries;
        this.countryvalues = Object.values(response.data.countries);
        this.ngxService.stop();
      });
  }
  checkValue() {
    // console.log(this.check);
    if(this.check==true){
      this.ngxService.start();
      this.httpClient
      .get(this.http.BASE_URI + 'listusers')
      .subscribe((response: any) => {
        this.elements = response.data.users.active;
        this.mdbTable.setDataSource(this.elements);
        this.previous = this.mdbTable.getDataSource();
        this.cdRef.detectChanges();
        this.ngxService.stop();
        this.check=false;
      });
    }else{
      this.ngxService.start();
      this.httpClient
      .get(this.http.BASE_URI + 'listusers')
      .subscribe((response: any) => {
        this.elements = response.data.users.deactive;
        this.mdbTable.setDataSource(this.elements);
        this.previous = this.mdbTable.getDataSource();
        this.cdRef.detectChanges();
        this.ngxService.stop();
        this.check=true;
      });
    }
    
  }
  onSend(e) {
    this.ngxService.start();
    let request = {
      "id":this.customer.id,
      "name": this.customer.name,
      "email": this.customer.email,
      "role": this.customer.roles,
      "phone": this.customer.mobile,
      "address": this.customer.address,
      "city": this.customer.city,
      "state": this.customer.state,
      "zipcode": this.customer.zipcode,
      "country": this.customer.country
    }
    let header = new HttpHeaders();
    header.set('Access-Control-Allow-Origin', '*');
    this.httpClient
      .post(this.http.BASE_URI + 'addUpdateUser', request)
      .subscribe((response: any) => {
        // console.log("Response", response)
        this.getdata();
        if (e == 'insert') {
          Swal.fire('Thank you...', 'Your Data Inserted Succesfully!', 'success');
        } else {
          Swal.fire('Thank you...', 'Your Data Edited Succesfully!', 'success');
        }
        this.onReset();
        this.customer.id="";
        this.cancel();
      });
  }
}
