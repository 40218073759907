import { Component, OnInit } from '@angular/core';
import * as CryptoJS from "crypto-js";
import { Router } from "@angular/router";
import { HttpClient } from "@angular/common/http";
import { environment } from "./../../../environments/environment";
import { HttpProvider } from "../../common/http/http.provider";
import { HttpHeaders } from '@angular/common/http';
import { NgxUiLoaderService } from "ngx-ui-loader"; // Import NgxUiLoaderService
import {
  FormGroup,
  FormControl,
  Validators,
  FormBuilder
} from "@angular/forms";
import { faEnvelope, faLock } from '@fortawesome/free-solid-svg-icons';
import Swal from 'sweetalert2/dist/sweetalert2.js';
@Component({
  selector: 'app-forget-password-link',
  templateUrl: './forget-password-link.component.html',
  styleUrls: ['./forget-password-link.component.css']
})
export class ForgetPasswordLinkComponent implements OnInit {
  faEnvelope = faEnvelope;
  faLock = faLock;
  registerForm: FormGroup;
  responsedata:any='';
  submitted = false;
  user: any = {
    email: '',
    password: ''
  }
  constructor(private formBuilder: FormBuilder, private _router: Router, private http: HttpProvider, private httpClient: HttpClient, private ngxService: NgxUiLoaderService) {
    this.registerForm = this.formBuilder.group({
      // username: ['', [Validators.required, Validators.email]],
      username: ['', [Validators.required, Validators.email]],
    });
  }
  ngOnInit() {
  }
  get f() { return this.registerForm.controls; }
  onSubmit() {
    this.submitted = true;
    // console.log('true')
    // stop here if form is invalid
    if (this.registerForm.invalid) {
      return;
    }
    this.ngxService.start();
    let request = {
      "email":this.user.email,
       type : 'user'
    }
    let header = new HttpHeaders();
    header.set('Access-Control-Allow-Origin', '*');
    this.httpClient
      .post(this.http.BASE_URI+'reset-password-request', request)
      .subscribe((response: any) => {
        this.ngxService.stop();
         console.log("Response", response.success)
        if(response.success == true){
          Swal.fire({
            title: 'Success',
            text: 'Mail sent successfully',
            icon: 'success',
            confirmButtonText: 'OK',
          }).then((result) => {
            this._router.navigateByUrl("/login");    
          })
        }else{
          Swal.fire({
            title: 'Error',
            text: response.message,
            icon: 'error',
            confirmButtonText: 'OK',
          }).then((result) => {
            this._router.navigateByUrl("/login");    
          })
        } 
    });
  }
  onReset() {
    this.submitted = false;
    this.registerForm.reset();
  }


}
