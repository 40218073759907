import { Component, OnInit } from '@angular/core';
import { faHome , faCaretRight} from "@fortawesome/free-solid-svg-icons";

import {​​​​​​SidenavComponent}​​​​​​ from "../sidenav/sidenav.component";
import {​​​​​​ Router }​​​​​​ from "@angular/router";
@Component({
  selector: 'app-adjusments',
  templateUrl: './adjusments.component.html',
  styleUrls: ['./adjusments.component.css']
})
export class AdjusmentsComponent implements OnInit {

  constructor(private component:SidenavComponent,private _router: Router) { }
  faHome = faHome;
  faCaretRight = faCaretRight;
  navOpen:boolean = false;
  ngOnInit(): void {
  }
  homeLink(){
  this.component.ngOnInit();
  if(localStorage.getItem('Role')=='Admin'){
  this._router.navigateByUrl("/adminDashboard");
  }else{
  this._router.navigateByUrl("/dashboard");
  }
  }
}
